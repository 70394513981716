export default class NavbarEnum {
    static BODY = 'body'
    static NAVBAR = '[data-element="navbar"]'
    static LOGO = '[data-element="navbar-logo"]'
    static ECOMODE = '[data-id="eco-bar"]'

    static BURGER_MENU_TRIGGER = '[data-trigger="burger-menu-toggle"]'
    static BURGER_MENU_PREV = '[data-trigger="burger-menu-prev"]'
    static BURGER_SUBMENU_TRIGGER = '[data-trigger="burger-submenu-toggle"]'
    static BURGER_SUBMENU = '[data-element="burger-submenu"]'
    static BURGER_SUBMENU_WRAPPER = '[data-element="burger-submenus-wrapper"]'

    static LANG_SWICTH_TRIGGER = "[data-trigger='lang-switcher']"
    static LANG_SWICTH_ELEMENT = "[data-element='lang-switcher-list']"

    //Toolbar Web Radio
    static WEB_RADIO = '[data-element="webradio"]'
    static WEB_RADIO_PLAYER = '[data-id="webradio-audio"]'

    //FOCUS ON INPUT SEARCH
    static SEARCH_TRIGGER = "[data-hc-modal][href='#modal-wp-search']"
    static SEARCH_MODAL_INPUT = "#modal-wp-search input[name='s']"
}
