import NavbarEnum from '../enumerators/navbar.enum'

export default class WebRadioComponent {
    constructor() {
        this.init()
    }

    init() {
        $(NavbarEnum.WEB_RADIO).on('click', (e) => {
            e.preventDefault()

            if ($(NavbarEnum.WEB_RADIO_PLAYER)[0].paused) {
                $(NavbarEnum.WEB_RADIO_PLAYER)[0].play()
            } else {
                $(NavbarEnum.WEB_RADIO_PLAYER)[0].pause()
            }
        })
    }
}
